// page container
.appointment-requests-container {
  th {
    &.appointment-type {
      min-width: 145px;
    }

    &.parent-name {
      min-width: 115px;
    }

    &.with-button {
      width: 90px;
    }
  }

  td .open-appointment-request {
    max-width: 76px;
  }

  .toggle-switches-column {
    flex-grow: 0;

    .toggle-switches-block {
      width: max-content;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}

// modal settings
.appointment-request-details-modal {
  .request-additional-info,
  .appointment-info {
    h3 {
      margin-bottom: 8px;
    }
  }

  .modal-main-info-data {
    &.of-request.short-variant {
      grid-template-areas:
        'dateTime parentFullName petName'
        'condition condition condition '
        'otherComments otherComments otherComments';
    }

    &.of-request.full-variant {
      grid-template-areas:
        'dateTime type doctorFullName'
        'parentFullName petName condition '
        'otherComments otherComments otherComments';
    }

    &.of-appointment {
      grid-template-areas: 'dateTime type doctor';
    }

    .modal-main-info-field.otherComments {
      .field-data {
        white-space: pre;
        text-wrap: wrap;
      }
    }
  }

  .modal-create-appointment-wrapper {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #dde1e5;

    h3 {
      margin-bottom: 10px;
    }

    .modal-create-appointment-block {
      @include default-modal-grid;

      grid-template-areas:
        'date time button'
        'type doctor .';
    }

    .save-update {
      margin-top: 26px;
    }

    .modal-create-appointment-field {
      .field-label {
        @include field-label;

        .required {
          color: #00b0ec;
          margin-left: 4px;
        }
      }

      // rewrite date picker styles
      .custom-date-picker {
        .dropdown-toggle-btn {
          border: 1px solid #dde1e5;
          margin-right: 0;
        }

        .mb-3 {
          margin-bottom: 0 !important;
        }

        input {
          border: none;
          padding: 6px 10px;
        }
      }

      // rewrite time picker styles
      .rc-time-picker {
        display: block;
        width: 100%;

        input {
          @include field-input;

          width: 100%;
          color: #212529;
          border-radius: 0;
        }

        .rc-time-picker-clear {
          display: none;
        }
      }

      // rewrite doctor select styles
      .doctors-select,
      .appointment-type-select {
        padding: 0;
        height: 40px;

        &__control {
          border: 1px solid #dde1e5;
          border-radius: 0;
          outline: none;
          box-shadow: none;
        }

        &__value-container {
          padding: 0;
          padding: 6px 10px;
        }

        &__placeholder {
          margin: 0;
        }

        &__input-container {
          max-width: 190px;
          margin: 0;
          padding: 0;
        }

        &__menu {
          left: 1px;
          width: calc(100% - 2px);
          border-radius: 0;
          z-index: 10;
        }

        input {
          height: auto;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .scrollable-modal-area.with-order {
    .appointment-info {
      order: 1;
      padding-bottom: 28px;
      border-bottom: 1px solid #dde1e5;
    }

    .request-additional-info {
      order: 2;
    }

    .specific-dates {
      order: 3;
    }

    .availability {
      order: 4;
    }
  }
}
