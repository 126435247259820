.pet-profile-wrapper {
  padding: 40px 0 48px;
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #322f31;
    margin-bottom: 40px;
    text-transform: capitalize;
    @include mobile {
      margin-bottom: 68px;
    }
  }
  .pet-details {
    background-color: #2c2c7c;
    border-radius: 12px;
    @include mobile {
      padding-bottom: 9px;
    }
    .profile-picture-wrapper {
      width: 160px;
      height: 159px;
      border-radius: 100px;
      position: relative;
      @include mobile {
        width: 94px;
        height: 94px;
        margin: -44px auto 0;
      }
      &::before {
        content: '';
        display: inline-block;
        height: 100%;
        position: absolute;
        width: 50%;
        background-color: white;
        z-index: 1;
        @include mobile {
          display: none;
        }
      }
      img {
        z-index: 6;
        border-radius: 100px;
        position: absolute;
        border: 2px solid white;
        @include mobile {
          border: 3px solid white;
        }
      }
    }
    .content {
      padding: 16px 0;
      @include mobile {
        padding: 15px 24px;
      }
      .content-label,
      .content-value {
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: white;
        text-transform: capitalize;
      }
      .content-label {
        opacity: 0.6;
        font-size: 15px;
        @include mobile {
          float: left;
        }
      }
      .content-value {
        font-size: 18px;
        margin-top: 4px;
        text-transform: capitalize;
        @include mobile {
          float: right;
          margin: 0;
        }
      }
    }
  }
  .additional-details-wrapper {
    border-radius: 12px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #e3e5e7;
    background-color: white;
    padding: 24px 40px;
    margin-top: 20px;
    @include mobile {
      padding: 20px 24px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-bottom: 8px;
      @include mobile {
        font-size: 16px;
      }
    }

    .additional-details {
      border-bottom: solid 1px #e3e5e7;
      padding: 20px 0;
      &:last-child {
        border: none;
      }
      .description-wrapper,
      .images-wrapper {
        float: left;
      }
      .description-wrapper {
        width: 70%;
        @include tablet-portrait {
          width: 50%;
        }
        @include mobile {
          width: 100%;
        }
        .title {
          opacity: 0.6;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          margin-bottom: 8px;
          @include mobile {
            font-size: 16px;
          }
        }
        .description {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          margin: 0;
          @include mobile {
            font-size: 12px;
          }
        }
      }

      .images-wrapper {
        width: 30%;
        text-align: justify;
        @include tablet-portrait {
          width: 50%;
        }
        @include mobile {
          width: 100%;
          margin-top: 20px;
          text-align: left;
        }
        > div {
          &:first-child {
            .image {
              margin: 0;
            }
          }
          width: 33.333%;
          display: inline-block;
        }
        .image {
          margin-left: 1px;
          border-radius: 3px;
          height: 84px;
          width: 97%;
        }
        .__react_modal_image__medium_img {
          width: auto;
        }
      }
    }
  }
}

.pet-appointment-requests-modal {
  $modal-max-height: 80vh;
  $body-paddings: 40px;
  $filters-block-height: 62px;
  $footer-height: 60px;

  transition: transform 0.3s ease-out, opacity 0.4s ease-out !important;

  &.hidden {
    transform: translateY(-100%) !important;
    opacity: 0;
  }

  &.modal-dialog {
    width: 90%;
    min-width: 1124px;
    max-width: 1324px;
    box-sizing: border-box;
    padding: 0 12px;
  }

  .modal-body {
    overflow-y: hidden;
    height: max-content;
    max-height: $modal-max-height;
    padding: $body-paddings;
  }

  .content {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .create-new-appointment {
      margin: 0;
      position: absolute;
      top: 0;
      right: 0;
      width: max-content;
      z-index: 2;
    }

    .modal-request-table-wrapper {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-height: 390px;

      .manage-patient-container {
        display: flex;
        flex-grow: 1;
      }
    }

    .appointment-requests-container {
      width: 100%;

      .row {
        margin: 0;
      }

      .search-field-and-date-filter {
        width: max-content;
        column-gap: 10px;

        #toggle-switch {
          height: 1rem;
          min-width: 2rem;
        }
      }

      .table-row {
        flex-grow: 1;
      }

      .table-wrapper {
        padding: 0;
        max-height: calc(
          #{$modal-max-height} - #{$body-paddings} * 2 - #{$filters-block-height} -
            #{$footer-height}
        );
        overflow: auto;

        .loyal-table {
          width: calc(100% - 2px);
          margin: 0 1px 1px 0;
        }
      }

      .no-data-message {
        margin-top: 16px;
      }
    }

    .buttons-group {
      width: max-content;
      margin-left: auto;
    }
  }
}
