.manage-patient-container {
  .loyal-staff-search {
    height: 44px;
    border: 1px solid #eff0ef;

    .form__form-group-search {
      padding: 7px 15px;
    }

    .form__custom-field--icon-left {
      border: none;
    }
  }

  .time-filter-wrapper {
    margin-bottom: 25px;
  }
}

.manage-patient {
  .content-wrapper {
    padding: 10px;
    border: 1px solid #e0e2e0;
    border-radius: 5px;
    margin-top: 20px;
  }

  .tab-content {
    @import './manage-appointments';

    .default-wrapper {
      padding: 30px 20px 20px 30px;
      background-color: #eef0f7;
      border: 1px solid #e0e2e0;
      border-radius: 0px 5px 5px 5px;
    }
  }

  .content-holder {
    margin-top: 15px;
  }

  .nav-tabs {
    border-bottom: none;
    margin-bottom: -2px;

    .nav-item {
      margin-bottom: -1px;
      margin-right: 1px;
      cursor: pointer;
    }

    .nav-link {
      border-color: #e0e2e0 #e0e2e0 #eeeef7 #e0e2e0;
      color: #000000;
      font-size: 16px;
      font-weight: 600;
    }

    .nav-link.active {
      border: 1px solid #e0e2e0;
      background-color: #eef0f7;
      border-bottom: none;
    }
  }
}

.pet-update-card-wrapper {
  --pet-avatar-width: 200px;

  .no-pets {
    background-color: #eef0f7;
    padding: 10px;
    border-radius: 0px 5px 5px 5px;
    border: 1px solid #e0e2e0;
  }

  .pet-inputs-item-wrapper {
    --input-column-width: 300px;

    width: 70%;
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(var(--input-column-width), 1fr)
    );
    grid-template-rows: auto;
    column-gap: 10px;

    @media screen and (max-width: 1240px) {
      --input-column-width: 250px;

      width: calc(100% - var(--pet-avatar-width));
    }
  }

  .pet-update-card {
    padding: 20px 20px 60px 20px;
    margin-bottom: 10px;
    margin-top: 0px;
    border: 1px solid #e0e2e0;
    background-color: #eef0f7;
    border-radius: 0px 5px 5px 5px;

    &:last-child {
      margin-bottom: 0px !important;
    }

    .pet-content-wrapper {
      position: relative;
    }

    .image-wrapper {
      position: relative;
      width: var(--pet-avatar-width);
      padding-right: 15px;
      padding-left: 15px;
    }

    .button-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-right: 15px;
      padding-left: 15px;
    }

    .status-label {
      text-align: center;
      padding: 3px 19px;
      border: 1px solid;
      border-radius: 5px;
      margin-bottom: 5px;
      @include font-size(14);
      font-weight: $font-weight-semi-bold;
      text-transform: capitalize;
      float: right;
      width: 92px;

      &.invited {
        border-color: #72bb38;
        color: #72bb38;
        background-color: #f7fbf3;
      }

      &.inactive {
        border-color: #d0021b;
        color: #d0021b;
        background-color: #f7fbf3;
      }
    }

    .button-panel {
      position: absolute;
      bottom: 0px;
      right: 0px;

      button {
        float: right;
        margin-right: 15px;
        margin-top: 5px;
      }
    }

    .view-contact-label {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -60px;
      width: 175px;
      height: 45px;
      border: 1px solid #e0e2e0;
      font-size: 15px;
      font-weight: 600;
      left: -5px;
      border-left: none;
      border-radius: 0px 6px 0px 6px;
      background-color: #eef0f7;
      cursor: pointer;

      &.expanded {
        border-bottom: none;
      }

      img {
        margin-left: 30px;
        width: 10px;
      }
    }

    .co-parent-container {
      margin-top: 20px;
      padding-top: 15px;
      border-top: 1px solid #e0e2e0;

      p {
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 15px;

        &.support-count {
          font-size: 14px;
          font-weight: 600;

          span {
            color: #72bb38;
          }
        }
      }

      .loyal-table {
        .header-tr th {
          font-size: 13px;
        }

        td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 140px;
        }
      }
    }
  }
}

.new-appointment-action {
  margin-right: 0;
  padding: 0 0 5px 25px;
  border: none !important;
  background-color: transparent;

  .lnr {
    font-size: 18px;
    position: absolute;
    left: 0;
  }
}
