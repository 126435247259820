.appointments-container {
  td#action-cell {
    button {
      width: 100%;
    }

    .six-layers {
      margin: 0 auto;
    }
  }
}
