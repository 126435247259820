.btn {
  &.btn-primary {
    background-color: #00b0ec;
    border-color: #00b0ec;
    font-weight: 700;
  }

  &.btn-outline {
    background-color: white;
    border: 1px solid #00b0ec;
    color: #00b0ec;
    @include font-details(14, $font-weight-bold);

    &.left-align-btn {
      float: right;
      width: 140px;
      margin-right: 0px;
      padding: 5px 0px 5px 50px;
      border: none;

      .lnr {
        font-size: 18px;
        position: absolute;
        left: 23px;
      }
    }
  }
}

.loyal-btn-dark {
  background-color: #15153e !important;
  border-color: #15153e !important;
}

.btn:before {
  background-color: transparent !important;
  transition: none;
}

.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary:active,
.btn.btn-outline-primary:active:focus {
  color: #00b0ec;
  background: transparent;

  svg {
    fill: #00b0ec;
  }
}

.loyal-btn-clear {
  min-width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: #15153e;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;

  &:hover {
    color: $white;
    background-color: darken(#15153e, 5%);
  }
}

.loyal-btn-deactivate {
  min-width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: #868886;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;

  &:hover {
    color: $white;
    background-color: darken(#868886, 5%);
  }
}

.loyal-btn-new-parent {
  min-width: 120px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #00b0ec;
  color: #00b0ec;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;

  .lnr {
    padding-right: 5px;
    font-weight: 600;
  }

  &:hover {
    color: $white;
    background-color: darken(#00b0ec, 5%);
  }
}

.loyal-btn-activate {
  min-width: 120px;
  height: 40px;
  border-radius: 5px;
  background-color: darken(#72bb38, 10%);
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
  transition: all 0.5s ease;

  &:hover {
    color: $white;
    background-color: darken(#72bb38, 15%);
  }
}

.loyal-btn-save-update {
  min-width: 122px;
  height: 40px;
  border-radius: 5px;
  background-color: #00b0ec;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 5px;
  transition: all 0.5s ease;
  flex-shrink: 0;

  &:hover {
    color: $white;
    background-color: darken(#00b0ec, 5%);
  }
}

.schedule {
  position: absolute;
  right: 16px;
  top: 35px;
}

// toggle switch
.toggle-wrapper {
  width: 100%;
  line-height: 24px;
  min-height: 24px;
  padding-right: 2.5em;
  padding-left: 0;
  margin: 0;

  .toggle-label {
    font-size: 14px;
    font-weight: 600;
    color: #50514f;
    margin: 0;
  }

  #toggle-switch {
    float: right;
    margin-left: 0;
    margin-right: -2.5em;
    cursor: pointer;

    &:not(:checked) {
      border-color: #00000040;
      // to override focus styles - the same background as reactstrap input[type="switch] has by default
      background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e');
    }

    &:checked {
      background-color: #00b0ec;
      border-color: #00b0ec;
    }

    &:focus {
      box-shadow: none;
    }
  }
}
