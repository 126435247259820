.profile-appointments {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .new-appointment-action {
    width: 204px;
    margin-right: 0px;
    padding: 5px 0px 5px 50px;
    border: none;
    background-color: transparent;
    span {
      font-size: 18px;
      position: absolute;
      left: 20px;
    }
    .lnr {
      font-size: 18px;
      position: absolute;
      left: 25px;
    }
  }
}
