@mixin title {
  font-size: 14px;
  font-weight: 600;
  color: #50514f;
}

.sort-by-wrapper {
  width: auto;

  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 10px;

  p {
    @include title;
  }

  select {
    font-size: 12px;
    width: min-content;
    border-radius: 0;
    border-color: lighten(#dde1e5, 5%);
    outline: none;
    cursor: pointer;

    &:is(:focus, :hover) {
      box-shadow: none;
      border-color: #dde1e5;
    }

    &#sortOrder {
      min-width: 140px;
    }
  }
}

.filter-pills-wrapper {
  display: flex;

  .title {
    @include title;

    line-height: 1;
    padding: 9px 0;
    white-space: nowrap;
    margin-right: 10px;
  }

  .empty-filters-message {
    font-size: 14px;
    color: #868886;
  }

  .filter-pills {
    min-height: 32px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    .badge {
      display: flex;
      align-items: center;
      gap: 4px;

      padding: 4px;
      padding-left: 10px;
      border: 1px solid #00b0ec;
      transition: all 0.15s ease-in-out;

      &.clear-all-filters-badge {
        padding: 7px 10px;
        cursor: pointer;

        color: grey;
        border: 1px solid grey;
        background-color: #fff !important;

        &:hover {
          background-color: grey !important;
          color: #fff;
        }
      }

      &.white-bg {
        background-color: #fff !important;
        color: #00b0ec;
      }

      &.blue-bg {
        background-color: #00b0ec !important;
        color: #fff;
      }
    }
  }
}
