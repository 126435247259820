.find-parent-wrapper {
  .parent-collapse {
    min-height: 100px;
    margin-top: 30px;
    .parent-card {
      background: $white;
      padding: 20px 15px;
      border-radius: 5px;
      width: 374px;
      border: 1px solid #e0e2e0;

      .parent-image {
        width: 72px;
        height: 72px;
        border-radius: 50px;
        margin-top: -6px;
      }
      .parent-name {
        color: #322f31;
        @include font-size(16);
        font-weight: $font-weight-bold;
        width: 249px;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .parent-name-2 {
        color: #818383;
        @include font-size(14);
        font-weight: $font-weight-regular;
        margin-bottom: 8px;
        margin-top: -8px;
      }
      .parent-email {
        color: #818383;
        @include font-size(14);
        font-weight: $font-weight-regular;
        margin-bottom: 0;
      }
      .parent-tel {
        color: $black;
        font-size: 14px;
        font-weight: $font-weight-semi-bold;
        margin-bottom: 0;
      }
      .parent-email,
      .parent-tel {
        margin-top: 2px;
      }
      .status-label {
        text-align: center;
        padding: 3px 0;
        border: 1px solid;
        border-radius: 5px;
        margin-bottom: 5px;
        @include font-size(14);
        font-weight: $font-weight-semi-bold;
        text-transform: capitalize;

        &.active {
          border-color: #72bb38;
          color: #72bb38;
          background-color: #f7fbf3;
        }

        &.removed {
          border-color: #d0021b;
          color: #d0021b;
          background-color: #f7fbf3;
        }

        &.inactive {
          border-color: #d06602;
          color: #d06602;
          background-color: #f7fbf3;
        }
        &.parent-actions {
          float: left;
          width: 95px;
          height: 32px;
          margin-bottom: 0;
          margin-top: 15px;
        }
      }

      .hover-box {
        position: relative;
        display: inline-block;
        height: auto;
        box-sizing: border-box;

        img {
        }
        .hover-box_background {
          width: 400px;
          height: 250px;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        .hover-box-layer_bottom {
          display: block;
        }

        .hover-box-layer_top {
          opacity: 0;
          position: absolute;
          top: -6px;
          left: 15px;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.6);
          color: #fff;
          padding: 10px;
          -webkit-transition: all 0.4s ease-in-out 0s;
          transition: all 0.4s ease-in-out 0s;
          width: 72px;
          height: 72px;
          border-radius: 50px;
          cursor: pointer;
        }
        &:hover .hover-box-layer_top,
        &.active .hover-box-layer_top {
          opacity: 1;
        }
        .hover-box-text {
          text-align: center;
          font-size: 12px;
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          font-weight: 600;
        }
      }
    }
    .action-wrapper {
      &.parent-actions {
        float: left;
        margin-top: 15px;

        .action {
          width: 102px;
          height: 32px;
          padding: 0;
          margin-left: 10px;
        }
      }
      .action {
        width: 100%;
        background: $white;
        border: 1px solid #00b0ec;
        color: #00b0ec;
        border-radius: 3px;
        padding: 10px 0;
        @include font-size(14);
        font-weight: $font-weight-semi-bold;
        cursor: pointer;
        font-family: 'Source Sans Pro', sans-serif;

        &:hover {
          background: #00b0ec;
          color: $white;
        }
      }
    }
    .no-parent {
      label {
        background-color: #faeef0;
        border-radius: 5px;
        border: 1px solid #e0e2e0;
        color: #d0021b;
        @include font-size(14);
        font-weight: $font-weight-regular;
        padding: 10px 15px;
        width: 100%;
      }
      .action-wrapper {
        .action {
          width: 60%;
          padding: 10px 0;
          font-weight: 700;
          cursor: pointer;
          max-width: 190px;
          margin-bottom: 0.5rem;
          span {
            font-size: 16px;
            position: absolute;
            left: 35px;
            font-weight: 700;
          }
        }
      }
    }
    .search-wrapper {
      width: 359px;
      margin-top: -26px;
      position: relative;
      .search-by {
        color: #50514f;
        @include font-size(12);
        font-weight: $font-weight-semi-bold;
        margin-bottom: 5px;
      }
      input {
        border-radius: 0;
        border-color: #e0e2e0;
        outline: none;
        box-shadow: none;
        @include font-size(14);
        font-weight: $font-weight-regular;
      }
      .list {
        background-color: $white;
        max-height: 130px;
        overflow-y: auto;
        border: 1px solid #e0e2e0;
        border-top: 0;
        transition-duration: 0.4s, 0s;
        transition-delay: 0s, 0.4s;
        position: absolute;
        width: 100%;
        .list-item {
          padding: 10px 15px;
          cursor: pointer;
          border-bottom: 1px solid #e0e2e0;
          &:last-child {
            border-bottom: none;
          }

          p {
            @include font-size(14);
            font-weight: $font-weight-regular;
            color: #272727;
            span {
              color: #868886;
            }
          }
        }
      }
    }
  }

  .parent-image:hover ~ p.text {
    color: rgb(88, 202, 230);
  }

  p.text {
    font-weight: 300;
    transition: color 1s ease;
  }
}
