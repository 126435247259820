.referral-doctor {
  .btn-new-referral-doctor {
    margin-top: 30px;
    font-weight: 600;
  }

  .btn-new-referral-doctor:hover {
    color: #1983c3 !important;
  }

  .btn-new-referral-doctor:focus {
    color: #1983c3 !important;
  }
}

.modal-referral-doctor {
  .checkbox-wrapper {
    border: 1px solid #f4f5f6 !important;
    padding: 15px !important;
    display: block;
  }

  .div-button {
    margin-top: -61px;
    margin-left: 71%;
  }

  .error {
    max-width: 300px !important;
  }
}
