.refresh-btn-board-container,
.refresh-btn-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.refresh-btn-container {
  .text {
    font-size: 14px;
    color: #6a6a6a;
    font-weight: 400;
  }

  .refresh-board-btn {
    border-radius: 5px;
    padding: 10px 25px;
    transition: all 0.4s;
    margin: 0 10px;
    font-size: 14px;
    background-color: transparent;
  }
}

.patient-tracking {
  .card {
    padding-bottom: 20px;
  }

  .smooth-dnd-draggable-wrapper {
    background-color: #eef0f7;
    min-width: unset;
    width: 100%;
    cursor: grab;
  }

  .overflow-top-scroll-wrapper {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 15px;
    background-color: rgb(238, 240, 247);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    > div {
      height: 100%;
    }
  }

  .sticky-top-60 {
    position: sticky;
    top: 60px;
    z-index: 1;
  }

  .board-scroll-wrapper {
    overflow-x: auto;
    overflow-y: auto;
    width: 100%;
    height: calc(100vh - 250px);

    .board-fit-content {
      width: fit-content;
    }
  }

  .card-body {
    padding: 0 30px 30px 30px;

    .logo {
      max-width: 128px;
      max-height: 128px;
      float: right;
      margin-top: 30px;
      margin-right: 35px;
    }

    .btn-search {
      background: #72bb38;
      color: #ffffff;
      margin-top: 32px;
    }

    .icon-maximize {
      .lnr-frame-expand {
        font-size: 30px;
        position: absolute;
        right: 15px;
        cursor: pointer;
        z-index: 100;

        background: url('../../../shared/img/full-screen.png');
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        top: 30px;

        &::before {
          content: none;
        }
      }
    }

    .search-wrap {
      padding-right: 0px;
    }

    .search-btn-wrap {
      padding-left: 5px;
    }

    .icon-minimize {
      .lnr-frame-contract {
        font-size: 30px;
        position: absolute;
        left: 10px;
        cursor: pointer;
        top: 8px;
      }
    }

    .board {
      .card {
        padding-bottom: 0px;

        .turquoise {
          background: #1cc7d0 !important;
        }

        .name {
          display: flex;
          flex-wrap: wrap;
          border-left: 3px solid red;
          padding: 0px 1px 0px 6px;
        }

        .first,
        .second {
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .date-time {
          background: #f6f6f6;
          font-size: 10px;
          padding: 4px 0px 4px 12px;
          color: #868886;

          .first {
            display: block;
          }

          .second {
            display: block;
          }
        }
      }
    }

    // end of board
    .maximize {
      position: fixed;
      top: -40px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 110;
      margin-top: 50px;
      height: 100vh;
      background-color: inherit;
      overflow: auto;

      .board-fit-content {
        width: 100%;
        // height: calc(100vh - 30px);

        .react-trello-board {
          width: 100%;

          > :first-child {
            width: 100%;
          }

          .smooth-dnd-container {
            width: 100%;
          }
        }
      }

      .overflow-top-scroll-wrapper {
        visibility: hidden;
      }

      .board-scroll-wrapper {
        height: calc(100vh - 100px);
        overflow-y: auto;

        .react-trello-lane {
          flex: 1;
        }
      }
    }

    .smooth-dnd-container {
      display: flex;
      width: fit-content;
      height: 100%;
      overflow: visible;

      section.react-trello-board {
        background: #eef0f7 !important;
        margin: 4px -1px 4px 0px;
        padding: 6px;
        border-radius: 0px;
        border-left: 1px solid #dcdfe0;
        border-right: 1px solid #dcdfe0;

        .smooth-dnd-container {
          background-color: #eef0f7;
          height: 98%;
        }

        header {
          text-align: left;
          padding: 0px;
          margin-left: -6px;
          margin-right: -6px;
          border-bottom: 2px solid #fff;
        }

        .iTzKeC {
          font-size: 14px;
          font-weight: 600;
          width: 100%;
          padding: 3px 4px 8px 8px;
        }

        .sc-EHOje {
          &.cqkFMI {
            margin-top: 0px;
            width: 100%;
            max-width: 100%;
            min-width: 100%;

            .smooth-dnd-container {
              .smooth-dnd-draggable-wrapper {
                .eoPYBZ {
                  .card {
                    .name {
                      .first {
                        color: #000000;
                        font-size: 8px;
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        padding: 0;
                        height: 24px;
                        padding-right: 0px;
                        font-weight: 600;

                        span {
                          font-size: 16px;
                          font-weight: 600;
                        }
                      }

                      .second {
                        color: #868886;
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 8px;
                        font-weight: 400;
                        line-height: 15px;
                        padding-bottom: 5px;
                        padding-right: 0px;

                        span {
                          font-size: 14px;
                          font-weight: 400;
                        }
                      }
                    }

                    .date-time {
                      font-size: 10px;
                      font-weight: 400;
                      text-transform: uppercase;
                      box-shadow: inset 0 1px 0 #edf3f6;
                      padding: 4px 0px 4px 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    div.react-trello-board {
      background-color: white !important;
      width: fit-content;
      height: 100%;
      padding-top: 0;
      height: fit-content;
      overflow: visible;

      &>div:first-child {
        width: fit-content;
      }

      .react-trello-lane {
        position: relative !important;
        background: #eef0f7 !important;
        max-width: 9vw;
        min-width: 105px;
        flex: 1;
        min-height: 60vh;
        margin-top: 0;

        >div {
          min-width: 50px;
          width: 100%;
          max-height: unset;
        }

        .smooth-dnd-container {
          flex-direction: column;
          width: 100%;
          border-top: 2px solid white;
          padding-top: 15px;
          margin-top: 0px;
        }

        & header {
          transition: all 1s ease 0s;
          position: sticky;
          top: 0px;
          background-color: inherit;
          z-index: 20;
        }
      }
    }

    article.react-trello-board {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      border: none;
      border-radius: 3px;

      .card {
        box-shadow: inset 0 -1px 0 #edf3f6;

        .name {
          padding: 0px 1px 0px 6px;
        }
      }
    }

    .react-trello-card {
      box-shadow: none !important;
      border-radius: 0px !important;
    }
  }
}

.ptb-board {
  .smooth-dnd-container {
    height: 98% !important;
    overflow: hidden;
  }

  .smooth-dnd-container:hover {
    background: #e4e6ec !important;
  }
}