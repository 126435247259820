@mixin scrollable-tab {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;

  .scrollable-modal-area {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}

@mixin default-modal-grid {
  --column-gap: 24px;

  display: grid;
  grid-template-columns: repeat(3, calc((100% - 2 * var(--column-gap)) / 3));
  gap: 16px 24px;
}

@mixin field-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.6;
  margin-bottom: 4px;
  padding-left: 4px;
}

@mixin field-input {
  display: block;

  min-height: 40px;
  font-size: 16px;

  border: 1px solid #dde1e5;
  padding: 6px 10px;
  margin: 0;
}

@mixin ellipsis-field {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.refill-request-details-modal,
.appointment-request-details-modal {
  // general modal settings
  --default-modal-margin-y: 1.75rem;
  --modal-tab-name-height: 44px;
  --tab-bg-color: #eef0f7;
  --tab-border-color: #e0e2e0;

  height: calc(100vh - var(--default-modal-margin-y) * 2);

  .modal-content {
    height: inherit;

    .modal-body {
      flex-grow: 1;
      padding: 20px;
      overflow-y: hidden;

      .nav-tabs {
        border-bottom: none;

        .nav-link {
          position: relative;
          color: #000000;
          font-size: 16px;
          font-weight: 600;
          text-decoration: none;
          border: 1px solid var(--tab-border-color);
          cursor: pointer;

          &.active {
            background-color: var(--tab-bg-color);
            border-bottom-color: var(--tab-bg-color);
          }
        }
      }

      .tab-content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        padding: 0;
        border: none;
        background: none;
        height: calc(100% - var(--modal-tab-name-height));

        & > section {
          padding: 20px;
          border-radius: 0px 5px 5px 5px;
          border: 1px solid var(--tab-border-color);
          background-color: var(--tab-bg-color);
        }
      }
    }
  }

  // close and save buttons
  .buttons-group-wrapper {
    width: 100%;

    .buttons-group {
      width: max-content;
      margin-left: auto;
    }
  }

  // main information tab
  .main-info-tab {
    @include scrollable-tab;

    .scrollable-modal-area {
      row-gap: 24px;
      padding-bottom: 2px;
    }

    .ellipsis {
      @include ellipsis-field;
    }
  }

  .modal-main-info-data {
    @include default-modal-grid;

    .modal-main-info-field {
      .field-label {
        @include field-label;
      }

      .field-data {
        @include field-input;
      }

      &.status {
        position: relative;

        .field-data {
          padding: 0;
          border: 1px solid #ced4da;
          background-color: #fff;
        }

        .dropdown-toggle {
          height: 100%;
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: space-between;

          font-size: 16px;
          font-weight: 400;
          color: #212529;

          padding: 6px 10px;
          margin: 0;
          border: none;
          background: none;
        }

        .dropdown__menu {
          min-width: calc(100% + 2px);
          border-radius: 0;
          top: 2px !important;
          left: -1px !important;
          border: 1px solid #ced4da;
        }

        .dropdown-item:is(:hover, :active) {
          background-color: #edf3f6;
          color: #212529;
          cursor: pointer;
        }
      }
    }
  }

  .modal-table {
    h3 {
      margin-bottom: 8px;

      &.small {
        font-size: 20px;
      }
    }

    table {
      margin: 0 auto;
      width: calc(100% - 2px);

      th p,
      tbody td {
        font-size: 14px;
      }
    }
  }

  // attachments tab
  .attachments-tab {
    @include scrollable-tab;

    .scrollable-modal-area {
      height: 100%;
      row-gap: 16px;
    }

    .files-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      gap: 4px;

      .file-container {
        display: flex;
        align-items: center;
        gap: 6px;
        width: max-content;

        button {
          font-size: 14px;
          background-color: transparent;
          outline: none;
          border: none;
          transition: background-color 0.3s ease;
        }

        .file-name {
          height: 40px;
          padding: 5px 0px 5px 10px;

          &:hover {
            text-decoration: underline;
            border-radius: 5px;
          }
        }

        .download-button {
          display: grid;
          place-items: center;
          border-radius: 50%;
          padding: 5px;

          &:hover {
            background-color: #d6d8de;
          }

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .carousel {
      .image-file {
        position: relative;

        img {
          width: 100%;
          height: 480px;
          object-fit: cover;
        }

        .file-name {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;

          font-size: 14px;
          text-align: center;
          margin: 0;
          padding: 0 6px;
          background: #ffffff8a;
        }
      }

      .carousel-indicators {
        top: 100%;
        bottom: auto;
        height: 40px;
        align-items: flex-end;
        margin-bottom: 0;

        button {
          background-color: #15153e;

          &:not(.active) {
            opacity: 0.3;
          }
        }
      }

      .pdf-file {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;

        width: 100%;
        height: 500px;
        background-color: #e0e2e0;
      }
    }
  }

  // internal comments tab
  .internal-comments-tab {
    --text-input-height: 104px;

    @include scrollable-tab;
    height: calc(100% - var(--text-input-height));
    row-gap: 24px;

    .input-wrapper {
      display: flex;
      gap: 10px;

      button {
        width: 120px;
        height: 42px;
        margin-top: 26px;

        &:not(.disabled) {
          background-color: #00b0ec;
        }

        &.saving {
          background-color: #00b0ec;
        }
      }
    }

    .textarea-input {
      margin-top: 0;

      .content-label {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.6;
        margin-bottom: 4px;
      }

      textarea {
        min-height: 40px;
        padding-top: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
      }
    }

    .comments-list {
      flex-grow: 1;
      gap: 16px;

      .comment-info {
        display: flex;
        gap: 10px;
        margin-bottom: 6px;
        margin-left: 8px;
        font-size: 14px;
      }

      .comment-text {
        border-left: 3px solid #bdc1bd;
        background-color: #fff;
        margin-bottom: 0;
        padding: 0 10px;
        font-size: 16px;
        font-style: italic;
        word-wrap: break-word;
        word-break: break-word;
        white-space: pre-wrap;
        margin: 0;
      }
    }
  }
}
