.manage-admin {
  .btn-new-admin {
    font-weight: 700;
  }

  .lnr-paw {
    font-size: 25px;
    color: gray;
    cursor: pointer;
  }
}

.modal-body {
  .form {
    display: inherit !important;
  }
}

.hover-mouse-pointer {
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 420px) {
  .modal-dialog--header {
    width: 100%;
    max-width: calc(96vw);
  }
}