// page container
.refill-requests-container {
  .refill-requests-dropdown {
    button#dropdown-toggle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 12px;
      color: #136596;

      &:not(.no-status) {
        text-decoration: underline;
      }

      &:hover {
        text-decoration: none;
      }
    }

    & .dropdown-item.disabled {
      opacity: 0.7;
    }
  }
}

// modal settings
.refill-request-details-modal {
  .modal-main-info-data {
    grid-template-areas:
      'dateTime status deliveryType'
      'address address address'
      'medication quantity dosage'
      'parentFullName petName doctorFullName'
      'comments comments comments';

    &.no-address {
      grid-template-areas:
        'dateTime status deliveryType'
        'medication quantity dosage'
        'parentFullName petName doctorFullName'
        'comments comments comments';
    }
  }
}
