@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

h5.modal-title {
  font-size: 1.25rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-content {
  padding: 0;
}

.modal-body {
  height: 80vh;
  overflow-y: auto;
}
