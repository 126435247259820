.find-parent-wrapper {
  .pet-card {
    background: $white;
    padding: 15px 15px 15px 35px;
    border-radius: 5px;
    width: 377px;
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #e0e2e0;
    min-height: 140px;
    position: relative;

    &.not-view-mode {
      cursor: pointer;
    }

    &.selected-pet {
      border: 1px solid #2294d2;
      box-shadow: 1px 0px 8px 0px #2294d2;
    }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    .pet-image {
      width: 72px;
      height: 72px;
      border-radius: 50px;
    }
    .pet-name {
      color: #322f31;
      @include font-size(16);
      font-weight: $font-weight-bold;
      margin-bottom: 8px;
      width: 232px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .pet-details {
      color: #818383;
      @include font-size(14);
      font-weight: $font-weight-regular;
      margin-bottom: 0;
      text-transform: capitalize;
      margin-top: 2px;
    }
    .status-label {
      text-align: center;
      padding: 3px 19px;
      border: 1px solid;
      border-radius: 5px;
      margin-top: 15px;
      @include font-size(14);
      font-weight: $font-weight-semi-bold;
      text-transform: capitalize;
      float: left;

      &.invited {
        border-color: #72bb38;
        color: #72bb38;
        background-color: #f7fbf3;
      }

      &.inactive {
        border-color: #d0021b;
        color: #d0021b;
        background-color: #fcf2f3;
      }
    }
    .select-wrapper {
      position: absolute;
      top: 8px;
      left: 8px;
      input[type='radio'] {
        display: none;
        &:checked {
          + .checkmark {
            &:before {
              content: '';
              width: 10px;
              height: 10px;
              position: absolute;
              background-color: #00b0ec;
              border-radius: 50px;
              left: 2px;
              top: 2px;
            }
          }
        }
      }
      .checkmark {
        cursor: pointer;
        content: ' ';
        width: 16px;
        height: 16px;
        background-color: $white;
        border-radius: 50px;
        border: 1px solid #00b0ec;
        position: relative;
        margin: 0;
      }
    }
  }

  .add-new-pet-register-btn {
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: transparent;
    color: #00b0ec;
    font-weight: 700;
    padding: 0px 0px 0px 40px;
    float: right;

    .lnr {
      font-size: 18px;
      position: absolute;
      left: 10px;
    }
  }
}
