.create-appointment {
  overflow-y: auto;

  .content-wrapper {
    padding: 10px;
    border: 1px solid #e0e2e0;
    border-radius: 5px;
    margin-top: 20px;

    .content-holder {
      background-color: #eef0f7;
      padding: 30px;
      border-radius: 5px;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        @include font-size(18);
        font-weight: $font-weight-regular;
        display: inline-block;
      }

      &.create {
        .custom-toggle-switch {
          width: 45px;
          height: 26px;

          .custom-toggle-slider {
            &:before {
              height: 20px;
              width: 20px;
            }
          }
        }
        .share-updates {
          margin-right: 10px;
          @include font-size(12);
        }
      }
    }
  }
  .highlight-green {
    color: #72bb38;
  }
}

.modal-content {
  .content-wrapper {
    h3 {
      float: left;
    }
  }

  .search-by {
    float: left;
  }

  .no-parent {
    button {
      float: left;
    }
  }
}
