.broadcast-news {
  .heading-text {
    font-size: 30px;
    margin-bottom: 5px;
    height: inherit;
  }
  .sub-title {
    color: #818383;
    font-size: 16px;
    margin: 0 0 30px 0;
  }
  .form-group {
    label {
      font-size: 12px;
      margin-bottom: 5px;
      &.content-label {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .content {
    height: 166px;
    margin-bottom: 10px;
    font-size: 14px;
    min-height: 34px;
  }
  .image-uploader-container {
    margin: 18px 0 0;
    .image-uploaders-wrapper {
      float: left;
      .loaded-img {
        height: 85px;
        width: 99px;
        margin-right: 16px;
        margin-bottom: 0;
        position: relative;
        border-radius: 4px;

        img {
          height: 85px;
          width: 99px;
          border-radius: 4px;
        }
        span {
          width: 20px;
          height: 20px;
          display: block;
          background: #d8d8d8;
          position: absolute;
          right: -10px;
          top: -10px;
          border-radius: 20px;
          cursor: pointer;
          background-image: url('../../../shared/img/image-remove-icon.svg');
          background-repeat: no-repeat;
        }
      }
      .uploader {
        display: none;
      }
      .image-uploader {
        background: #e7ebf4;
        height: 85px;
        width: 99px;
        border-radius: 4px;
        background-image: url('../../../shared/img/image-upload-icon.svg');
        background-repeat: no-repeat;
        background-position: 50%;
        margin-right: 16px;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }
  .dropdowns-wrapper {
    margin-top: 40px;
  }
  .btn-broadcast {
    margin: 50px 0 0;
  }
  .broadcast-audience {
    width: 350px;
    .blocks-wrapper {
      .states-block {
        .checkbox-label {
          max-width: inherit !important;
          text-transform: capitalize;
        }
      }
    }
  }
}
